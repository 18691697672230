export const menuItems = [
  {
    name: "#hero",
    label: "Home",
    items: [
      {
        name: "About",
        label: "About Us"
      },
      {
        name: "Careers",
        label: "Careers"
      },
      {
        name: "contact",
        label: "Contact Us"
      }
    ],
  },
  {
    name: "#industries",
    label: "Industries",
  },
  {
    name: "#technologies",
    label: "Technologies",
  },
  {
    name: "#services",
    label: "Services",
  },
  {
    name: "#affiliates",
    label: "Affiliates",
    items: [
      {
        name: "affiliate-contact",
        label: "Application Form"
      },
      {
        name: "agreement-terms",
        label: "Agreement Terms"
      }
    ],
  },
  {
    name: "#customers",
    label: "Customers",
    items: [
      {
        name: "customer-contact",
        label: "Registration Form"
      }
    ],
  },
  {
    name: "#bmodels",
    label: "Business Models",
  }
];
